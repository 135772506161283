
import { useStore, mapGetters } from "vuex";
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonTitle,
} from "@ionic/vue";
import {
  personCircleOutline,
  checkmarkCircleOutline,
  attachOutline,
  bookOutline,
  bookmarkOutline,
} from "ionicons/icons";
import { useRouter } from "vue-router";
import { ref } from "vue";

export default {
  name: "ManagePage",
  components: {
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonHeader,
    IonToolbar,
    IonTitle,
  },
  computed: {
    ...mapGetters({
      appBranding: "getAppBranding",
      currentCoach: "getCoach",
    }),
  },
   setup() {
    const store = useStore();
    const menuStyle = store.state.menu;
    const router = useRouter(); 
    const axLogout = ()=> store.dispatch("axLogout")
    const routePush = (item: any) => {

      //Check for Logout to Run Logout 
      if(item.href=='/logout'){
         axLogout().then( ()=> router.push(item.href))
      }
     
      if(item.type == 'int' && item.href !="/logout"){
         router.push(item.href);
      }
      else {
        window.open(item.href,item.label)
      }
     
    }

    const menuItems = ref([
      {
        label: "Personal Information",
        icon: "address-card",
         type:"int",
        href: "/tabs/personal-information",
      },
      {
        label: "Privacy Policy",
        icon: "lock-keyhole",
        type:"ext",
        href: "https://www.tk2p.com/ruprivacypolicy",
      },
      {
        label: "About Results Unleashed",
        icon: "circle-info",
         type:"int",
        href: "/tabs/about",
      },
      {
        label: "Logout",
        icon: "circle-info",
         type:"int",
        href: "/logout",
      },
    ]);
    return {
      routePush,
      menuStyle,
      menuItems,
      icons: {
        personCircleOutline,
        checkmarkCircleOutline,
        attachOutline,
        bookOutline,
        bookmarkOutline,
      },
    };
  },
};
